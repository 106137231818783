import React from 'react';
import { ProductItem } from '../../AppTypes';
import { Message } from 'semantic-ui-react';
import PlanForm from '../forms/PlanForm';
import DetailsForm from '../forms/DetailsForm';
import PaymentForm from '../forms/PaymentForm';
import SuccessStep from './SuccessStep';

interface Props {
  step: number;
  users: string;
  products: Array<ProductItem>;

  plan?: ProductItem;
  currency: string;
  subscription?: string;
  handleUsers: any;
  handlePlan: any;
  handleCurrency: any;
  details: {
    name?: string;
    companyName?: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    postcode?: string;
    country: string;
    vat?: string;
    consent: boolean;
    setName: any;
    setCompanyName: any;
    setEmail: any;
    setPhone: any;
    setAddress1: any;
    setAddress2: any;
    setCity: any;
    setPostcode: any;
    setCountry: any;
    setVat: any;
    setConsent: any;
  };
  euCountries?: Array<string | undefined>;
}

const FormSteps: React.SFC<Props> = ({
  step,
  products,
  users,
  currency,
  plan,
  subscription,
  handleCurrency,
  handleUsers,
  handlePlan,
  details,
  euCountries,
}) => {
  switch (step) {
    case 1:
      return (
        <PlanForm
          products={products}
          users={users}
          setUsers={handleUsers}
          currency={currency}
          handleCurrency={handleCurrency}
          plan={plan}
          handlePlan={handlePlan}
        />
      );
    case 2:
      return <DetailsForm users={users} plan={plan} details={details} />;
    case 3:
      return (
        <PaymentForm
          users={users}
          plan={plan}
          country={details.country}
          euCountries={euCountries}
        />
      );
    case 4:
      return <SuccessStep subscription={subscription} email={details.email} />;
    default:
      return <Message negative>Oops - Something went wrong!</Message>;
  }
};

export default FormSteps;
