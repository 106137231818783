import React from 'react';
import { Form, Modal } from 'semantic-ui-react';
import TotalCost from '../fragments/TotalCost';
import { ProductItem } from '../../AppTypes';
import CountrySelect from '../fragments/CountrySelect';

interface Props {
  plan?: ProductItem;
  users: string;

  details: {
    name?: string;
    companyName?: string;
    email?: string;
    phone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    postcode?: string;
    country: string;
    vat?: string;
    consent: boolean;
    setName: any;
    setCompanyName: any;
    setEmail: any;
    setPhone: any;
    setAddress1: any;
    setAddress2: any;
    setCity: any;
    setPostcode: any;
    setCountry: any;
    setVat: any;
    setConsent: any;
  };
}

const DetailsForm: React.SFC<Props> = ({ plan, users, details }) => {
  return (
    <Form size={'large'}>
      <TotalCost plan={plan} users={users} />
      <Form.Input
        id="name"
        label="Name"
        placeholder="Full Name"
        value={details.name}
        onChange={(event) => details.setName(event)}
        required
      />
      <Form.Input
        id="company-name"
        label="Company Name"
        placeholder="Company Name"
        value={details.companyName}
        onChange={(event) => details.setCompanyName(event)}
        required
      />
      <Form.Input
        id="email"
        label="Email"
        placeholder="example@your-company.com"
        value={details.email}
        onChange={(event) => details.setEmail(event)}
        required
      />
      <Form.Input
        id="phone-number"
        label="Phone Number"
        placeholder="+44 123 123 1234"
        value={details.phone}
        onChange={(event) => details.setPhone(event)}
        required
      />
      <Form.Input
        id="address-1"
        label="Address Line 1"
        placeholder="Address"
        value={details.address1}
        onChange={(event) => details.setAddress1(event)}
        required
      />
      <Form.Input
        id="address-2"
        label="Address Line 2"
        placeholder="Address"
        value={details.address2}
        onChange={(event) => details.setAddress2(event)}
      />
      <Form.Input
        id="city"
        label="City/Town"
        placeholder="City/Town"
        value={details.city}
        onChange={(event) => details.setCity(event)}
      />
      <Form.Input
        id="postcode"
        label="Postcode/ZIP Code"
        placeholder="Postcode/ZIP Code"
        value={details.postcode}
        onChange={(event) => details.setPostcode(event)}
        required
      />
      <CountrySelect
        country={details.country}
        setCountry={details.setCountry}
      />
      <Form.Input
        id="vat"
        label="VAT Number (UK/EU only)"
        placeholder="VAT Number"
        value={details.vat}
        onChange={(event) => details.setVat(event)}
      />
      <div
        className="grey-box"
        style={{
          marginTop: '25px',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Form.Checkbox
          className="mb-0"
          checked={details.consent}
          onChange={details.setConsent}
        />
        <div style={{ marginLeft: '12px', marginTop: '-5px', lineHeight: 1.4 }}>
          I agree to the
          <Modal
            closeIcon
            trigger={
              <span className="quasi-link">
                &nbsp;Terms and Conditions&nbsp;
              </span>
            }
            header="Terms And Conditions"
            content={
              <div style={{ height: '70vh' }}>
                <iframe
                  title="terms-conditions"
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  src="https://www.immsuite.com/terms-and-conditions/content.html"
                />
              </div>
            }
          />
          and have read the
          <Modal
            closeIcon
            trigger={
              <span className="quasi-link">&nbsp;Privacy Policy&nbsp;</span>
            }
            header="Privacy Policy"
            content={
              <div style={{ height: '70vh' }}>
                <iframe
                  title="privacy-policy"
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  src="https://www.immsuite.com/privacy-policy/content.html"
                />
              </div>
            }
          />
        </div>
      </div>
    </Form>
  );
};

export default DetailsForm;
