import React from 'react';
import { Icon } from 'semantic-ui-react';

interface Props {
  subscription: string | undefined;
  email: string | undefined;
}

const SuccessStep: React.SFC<Props> = ({ subscription, email }) => {
  return subscription ? (
    <div className="success-container">
      <Icon
        name="check circle"
        size="massive"
        color="green"
        style={{ marginBottom: '40px' }}
      />
      <h1 style={{ color: 'unset', marginTop: 0, marginBottom: '20px' }}>
        Payment Successful
      </h1>

      <p
        className="font-size-17"
        style={{ marginBottom: '35px', lineHeight: 1.6 }}
      >
        Thank you! We are setting up your <b>IM&M service</b> now and will email{' '}
        <b>{email}</b> once it is ready. This could take up to two business
        days.
      </p>
      <div className="text-grey">
        Payment ref: <b>{subscription}</b>
      </div>
    </div>
  ) : null;
};

export default SuccessStep;
