import React from 'react';
import { Divider } from 'semantic-ui-react';
import { ProductItem } from '../../AppTypes';
import { getMonthlyCostText } from '../../utils';

interface Props {
  plan?: ProductItem;
  users: string;
  country?: string;
  euCountries?: Array<string | undefined>;
}

const OrderSummary: React.SFC<Props> = ({
  plan,
  users,
  country,
  euCountries,
}) => {
  const cost = getMonthlyCostText(plan, users);
  const isUnitedKingdom = country === 'GB';
  const isEu = euCountries?.includes(country);
  const vatAmount = isUnitedKingdom ? (Number(cost) * 0.2).toFixed(2) : 0;

  const total = (Number(cost) + Number(vatAmount)).toFixed(2);

  return (
    <>
      {plan ? (
        <div className="order-summary font-size-16 mb-4">
          <h5 className="text-grey" style={{ marginBottom: '28px' }}>
            ORDER SUMMARY
          </h5>
          <div className="row">
            <div>
              {plan.name} Term - {users} x Users
            </div>
            <div>
              {plan.symbol}
              {cost}
            </div>
          </div>

          {isUnitedKingdom || isEu ? (
            <div className="row">
              <div>VAT {isEu ? 'Reverse Charge' : null}</div>
              <div>
                {plan.symbol}
                {vatAmount}
              </div>
            </div>
          ) : null}
          <Divider />

          <div className="row">
            <div>
              <div>
                <b>TOTAL</b>
              </div>
              <div className="text-grey">per month</div>
            </div>
            <div>
              {plan.symbol}
              <b>{total}</b>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OrderSummary;
