import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

const dsn = (window as any).SENTRY_DSN;
if (dsn.includes('https://')) {
  // window.SENTRY_DSN will be injected by flask, but only for a production build.
  // for development, index.html isn't templated by flask so the value here will
  // be a chunk of j2 template. ignore it if it doesn't start with https://.
  Sentry.init({ dsn });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
