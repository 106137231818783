import React from 'react';
import { Grid } from 'semantic-ui-react';
import { ProductItem } from '../../AppTypes';
import { getMonthlyCostText } from '../../utils';

interface Props {
  plan?: ProductItem;
  users: string;
}

const TotalCost: React.SFC<Props> = ({ plan, users }) => {
  const cost = getMonthlyCostText(plan, users);

  return (
    <>
      {plan ? (
        <div className="grey-box">
          <Grid>
            <Grid.Column width={8}>
              <div className="mb-2 font-size-20">
                {plan.name} <small className="text-grey">Term</small>
              </div>
              <div className="font-size-20">
                {users} <small className="text-grey">Users</small>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{ textAlign: 'right' }}>
                <div>
                  <span style={{ fontSize: '30px' }}>
                    <small>{plan.symbol}</small>
                    <b>{cost}</b>
                  </span>
                  <span className="text-grey"> ex VAT</span>
                </div>
                <div className="text-grey">per month</div>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default TotalCost;
