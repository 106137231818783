import {
  CustomerOptions,
  PaymentOptions,
  Subscription,
  Products,
} from './AppTypes';

const getOptions = () => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

const postOptions = (options = {}) => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
  };
};

const checkData = (data: any, error: string): void => {
  if (!data || data.error) {
    console.log(`${error}: ${data.error}`);
    throw new Error(data.error);
  }
};

const createSubscription = (options: PaymentOptions): Promise<Subscription> => {
  return window
    .fetch(`/create-subscription`, postOptions(options))
    .then((response) => {
      return response.status === 200 ? response.json() : null;
    })
    .then((data) => {
      checkData(data, 'Subscriptions API Error');
      return data.subscription;
    });
};

const createCustomer = (options: CustomerOptions): Promise<string> => {
  return window
    .fetch(`/create-customer`, postOptions(options))
    .then((response) => {
      return response.status === 200 ? response.json() : null;
    })
    .then((data) => {
      checkData(data, 'Customer API Error');
      return data.customer.id;
    });
};

const getProductDetails = (): Promise<Products> => {
  return window
    .fetch(`/product-details`, getOptions())
    .then((response) => {
      return response.status === 200 ? response.json() : null;
    })
    .then((data) => {
      checkData(data, 'ProductDetails API Error');
      return data;
    });
};

const getEuCountries = (): Promise<Array<string>> => {
  return window
    .fetch('/eu-countries', getOptions())
    .then((response) => {
      return response.status === 200 ? response.json() : null;
    })
    .then((data) => {
      checkData(data, 'ProductDetails API Error');
      return data;
    });
};

const getPublicStripeKey = (): Promise<string> => {
  return window
    .fetch(`/public-key`, getOptions())
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    })
    .then((data) => {
      checkData(data, 'PublicStripeKey API Error');
      return data.publicKey;
    });
};

const api = {
  getPublicStripeKey,
  getProductDetails,
  getEuCountries,
  createCustomer,
  createSubscription,
};

export default api;
