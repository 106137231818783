import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import api from './api';
import MainForm from './components/MainForm';
import logo from './static/logo.png';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

const stripePromise = api.getPublicStripeKey().then((key) => loadStripe(key));

function App(): JSX.Element {
  return (
    <div className="App">
      <header className="nav-bar">
        <a href="https://www.immsuite.com">
          <img src={logo} alt="Logo" />
        </a>
      </header>
      <Elements stripe={stripePromise}>
        <MainForm />
      </Elements>
    </div>
  );
}

export default App;
