import React from 'react';
import { ProductItem } from '../../AppTypes';
import { getMonthlyCostText } from '../../utils';

interface Props {
  plan?: ProductItem;
  users: string;
}

const TotalCost: React.SFC<Props> = ({ plan, users }) => {
  const cost = getMonthlyCostText(plan, users);

  return (
    <>
      {plan ? (
        <div style={{ textAlign: 'right', marginBottom: '18px' }}>
          <div>
            <span style={{ fontSize: '30px' }}>
              {plan.symbol}
              <b>{cost}</b>
            </span>
            <span className="text-grey">&nbsp;ex VAT</span>
          </div>
          <div className="text-grey">per month</div>
        </div>
      ) : null}
    </>
  );
};

export default TotalCost;
