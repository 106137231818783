import React from 'react';
import { ProductItem } from '../../AppTypes';
import { Menu } from 'semantic-ui-react';

interface Props {
  products?: Array<ProductItem>;
  plan?: string;
  handleClick: any;
}

const ProductList: React.SFC<Props> = ({ products, plan, handleClick }) => {
  return (
    <Menu color="orange" fluid stackable widths={3}>
      {products
        ? products.map((product) => {
            return (
              <Menu.Item
                key={product.id}
                name={product.name}
                value={product.id}
                onClick={handleClick}
                active={plan === product.id}
              >
                <div className="p-3">
                  <h3 className="mb-3">{product.name}</h3>
                  <div className="mb-2 text-grey">
                    {product.symbol}
                    {product.pricePlan.initialPrice.toFixed(2)} for first 100
                    users
                  </div>
                  <div style={{ fontSize: '17px' }}>
                    <b>
                      {product.symbol}
                      {product.pricePlan.perPerson.toFixed(2)}
                    </b>{' '}
                    <span style={{ fontSize: '.9em' }}>
                      per additional user
                    </span>
                  </div>
                </div>
              </Menu.Item>
            );
          })
        : null}
    </Menu>
  );
};

export default ProductList;
