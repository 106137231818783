import { ProductItem } from './AppTypes';

export const getMonthlyCostText = (
  plan: ProductItem | undefined,
  users: string
): string => {
  if (!plan) {
    return '';
  }

  const initialPrice = Number(plan?.pricePlan.initialPrice);
  const additionalPrice =
    Number(plan?.pricePlan.perPerson) * (Number(users) - 100);
  const total = initialPrice + additionalPrice;

  return total.toFixed(2);
};
