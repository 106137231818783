import React from 'react';
import { Form, Input, Popup, Button, Select, Grid } from 'semantic-ui-react';
import { ProductItem } from '../../AppTypes';
import ProductList from '../fragments/ProductList';
import Currencies from '../../static/currencies.json';

interface Props {
  products?: Array<ProductItem>;
  users: string;
  setUsers: any;
  currency: string;
  handleCurrency: any;
  plan?: ProductItem;
  handlePlan: any;
}

const PlanForm: React.SFC<Props> = ({
  products,
  users,
  setUsers,
  currency,
  handleCurrency,
  plan,
  handlePlan,
}) => {
  const options = Currencies.map((item) => {
    return {
      key: item.id,
      value: item.id.toLowerCase(),
      flag: item.flag,
      text: `${item.id} - ${item.name}`,
    };
  });
  return (
    <Form>
      <div className="form-heading">
        Select the&nbsp;
        <Popup hoverable trigger={<b className="line-accent">right plan</b>}>
          <div style={{ padding: '5px' }}>
            Need help finding the right plan for your business? We&apos;d be
            happy to personally guide you.
            <br />
            <Button
              style={{ marginTop: '10px' }}
              primary
              as="a"
              href="mailto:info@immsuite.com"
            >
              Email Us
            </Button>
          </div>
        </Popup>
        &nbsp;for your needs
      </div>

      <Grid stackable style={{ marginBottom: '40px' }}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={9}>
            <div className="font-size-17">
              What is your preferred payment currency?
            </div>
          </Grid.Column>
          <Grid.Column width={7}>
            <Select
              options={options}
              fluid
              id="currency"
              value={currency}
              onChange={handleCurrency}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid stackable style={{ marginBottom: '55px' }}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={9}>
            <div className="mb-1 font-size-17">
              How many <b>users</b> need to book spaces?
            </div>
            <div className="text-grey font-size-14">Minimum of 100 users</div>
          </Grid.Column>
          <Grid.Column width={7}>
            <Input
              icon="users"
              iconPosition="left"
              min={100}
              name="users"
              fluid
              style={{ fontSize: '18px' }}
              onChange={(event) => setUsers(event)}
              type="number"
              value={users}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="mb-4 font-size-17">How many months minimum?</div>
      <div style={{ marginBottom: '35px' }}>
        <ProductList
          products={products}
          plan={plan ? plan.id : undefined}
          handleClick={handlePlan}
        />
      </div>
    </Form>
  );
};

export default PlanForm;
