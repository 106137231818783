import React from 'react';
import { Step } from 'semantic-ui-react';

interface Props {
  step: number;
}

const StepMenu: React.SFC<Props> = ({ step }) => {
  return (
    <Step.Group ordered fluid widths={3} className="step-group">
      <Step completed={step > 1} active={step === 1}>
        <Step.Content>
          <Step.Title> Pick Your Plan</Step.Title>
          {/*<Step.Description>Choose your shipping options</Step.Description>*/}
        </Step.Content>
      </Step>

      <Step completed={step > 2} active={step === 2}>
        <Step.Content>
          <Step.Title> Business Details</Step.Title>
          {/*<Step.Description>Enter billing information</Step.Description>*/}
        </Step.Content>
      </Step>

      <Step completed={step > 3} active={step === 3}>
        <Step.Content>
          <Step.Title> Payment</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};

export default StepMenu;
