import React from 'react';
import { Form } from 'semantic-ui-react';
import { CardElement } from '@stripe/react-stripe-js';
import { ProductItem } from '../../AppTypes';
import OrderSummary from '../fragments/OrderSummary';

interface Props {
  plan?: ProductItem;
  users: string;
  country?: string;
  euCountries?: Array<string | undefined>;
}

const PaymentForm: React.SFC<Props> = ({
  plan,
  users,
  country,
  euCountries,
}) => {
  const options = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <Form size={'large'}>
      <OrderSummary
        users={users}
        plan={plan}
        country={country}
        euCountries={euCountries}
      />
      <div className="field">
        <label>Card Details</label>
      </div>
      <CardElement
        className="sr-input sr-card-element mb-3"
        options={options}
      />
    </Form>
  );
};

export default PaymentForm;
