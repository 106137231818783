import React, { useEffect, useState, ChangeEvent } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Loader, Message } from 'semantic-ui-react';
import api from '../api';
import { ProductItem, CustomerOptions, Products } from '../AppTypes';
import FormSteps from './fragments/FormSteps';
import StepMenu from './fragments/StepMenu';
import InitialPriceSummary from './fragments/InitialPriceSummary';

const MainForm: React.SFC = () => {
  const [step, setStep] = useState<number>(1);
  const [products, setProducts] = useState<Products | undefined>();
  const [displayedProducts, setDisplayedProducts] = useState<
    Array<ProductItem> | undefined
  >();
  const [error, setError] = useState<string | undefined>();
  const [processing, setProcessing] = useState<boolean>(false);

  const [plan, setPlan] = useState<ProductItem | undefined>();
  const [users, setUsers] = useState<string>('100');

  const [name, setName] = useState<string | undefined>();
  const [companyName, setCompanyName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [address1, setAddress1] = useState<string | undefined>();
  const [address2, setAddress2] = useState<string | undefined>();
  const [city, setCity] = useState<string | undefined>();
  const [postcode, setPostcode] = useState<string | undefined>();
  const [country, setCountry] = useState<string>('GB');
  const [euCountries, setEuCountries] = useState<Array<string | undefined>>();
  const [currency, setCurrency] = useState<string>('gbp');

  const [customerId, setCustomerId] = useState<string | undefined>();

  const [vat, setVat] = useState<string | undefined>();
  const [consent, setConsent] = useState<boolean>(false);

  const [subscription, setSubscription] = useState<string>();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    api.getProductDetails().then((result) => {
      setProducts(result);
      setDisplayedProducts(result.gbp);
    });
    api.getEuCountries().then((result) => {
      setEuCountries(result);
    });
    window.onbeforeunload = () => {
      return 'Navigating away now will result in your order not being completed!';
    };
  }, []);

  const ready = !!products;

  const nextstep = () => {
    if (step >= 3) throw new Error('Step should not be greater than 3');
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    if (step <= 1) throw new Error('Step should not be less than 1');
    resetSensitive();
    setStep(step - 1);
    window.scrollTo(0, 0);
  };

  const resetSensitive = (): void => {
    // Throw out any saved customer/subscription details to prevent errors
    setConsent(false);
    setCustomerId(undefined);
    setSubscription(undefined);
  };

  const handleUsers = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsers(event.target.value);
  };

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
  };

  const handleAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  };

  const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  };

  const handlePostcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostcode(event.target.value);
  };

  const handleCountry = (
    event: React.ChangeEvent<HTMLInputElement>,
    target: any
  ) => {
    setCountry(target.value);
  };

  const handleCurrency = (
    event: React.ChangeEvent<HTMLInputElement>,
    target: any
  ) => {
    setCurrency(target.value);
    setPlan(undefined);
    switch (target.value) {
      case 'gbp':
        setDisplayedProducts(products?.gbp);
        break;
      case 'usd':
        setDisplayedProducts(products?.usd);
        break;
      case 'eur':
        setDisplayedProducts(products?.eur);
        break;
      case 'hkd':
        setDisplayedProducts(products?.hkd);
        break;
      case 'aed':
        setDisplayedProducts(products?.aed);
        break;
    }
  };

  const handlePlan = (
    event: React.ChangeEvent<HTMLInputElement>,
    selection: { name: string; value: string }
  ) => {
    if (displayedProducts)
      setPlan(displayedProducts.find((plan) => plan.id === selection.value));
  };

  const handleVat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVat(event.target.value);
  };

  const handleConsent = () => {
    setConsent(!consent);
  };

  const completePayment = (subscription: string) => {
    setSubscription(subscription);
    setError(undefined);
    // Once we have a subscription we can allow people to leave in peace
    window.onbeforeunload = null;
    window.scrollTo(0, 0);
    setStep(4);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setProcessing(true);
    if (!name || !email || !address1 || !country) {
      setError('Please fill in all required fields');
      window.scrollTo(0, 0);
      setProcessing(false);
    } else {
      try {
        const options: CustomerOptions = {
          name,
          companyName,
          email,
          phone,
          address: {
            line1: address1,
            line2: address2,
            city,
            postcode,
            country,
          },
          vat,
        };
        const response = await api.createCustomer(options);
        setError(undefined);
        setCustomerId(response);
        nextstep();
      } catch (error) {
        setError(error.message);
        window.scrollTo(0, 0);
      }
      setProcessing(false);
    }
  };

  const handlePayment = async (event: any) => {
    event.preventDefault();
    setProcessing(true);
    const element = elements?.getElement(CardElement);
    if (element) {
      const response = await stripe?.createPaymentMethod({
        type: 'card',
        card: element,
      });
      if (response?.error) {
        setError(response.error.message);
        window.scrollTo(0, 0);
      } else if (customerId && response?.paymentMethod && plan) {
        const options = {
          customerId: customerId,
          paymentMethodId: response?.paymentMethod.id,
          price: plan?.price,
          quantity: users,
        };
        try {
          const data = await api.createSubscription(options);
          if (data.status === 'active') {
            completePayment(data.subscription);
          } else if (data.paymentIntent.status === 'requires_action') {
            const auth = await stripe?.confirmCardPayment(
              data.paymentIntent.clientSecret,
              {
                payment_method: data.paymentMethodId,
              }
            );
            if (auth?.paymentIntent?.status === 'succeeded') {
              completePayment(data.subscription);
            } else if (auth?.error?.message) {
              setError(auth.error?.message);
              window.scrollTo(0, 0);
            }
          }
        } catch (error) {
          setError(error.toString());
          window.scrollTo(0, 0);
        }
      }
      setProcessing(false);
    }
  };

  const detailsProps = {
    name,
    companyName,
    email,
    phone,
    address1,
    address2,
    city,
    postcode,
    country,
    vat,
    consent,
    setName: handleName,
    setCompanyName: handleCompany,
    setEmail: handleEmail,
    setPhone: handlePhone,
    setAddress1: handleAddress1,
    setAddress2: handleAddress2,
    setCity: handleCity,
    setPostcode: handlePostcode,
    setCountry: handleCountry,
    setVat: handleVat,
    setConsent: handleConsent,
  };

  return (
    <main className="form-container">
      {step <= 3 ? <StepMenu step={step} /> : null}
      {error ? (
        <Message error>
          <Message.Header>Error</Message.Header>
          {error}
        </Message>
      ) : null}

      <Loader active={!ready}>Loading</Loader>

      {displayedProducts ? (
        <FormSteps
          step={step}
          users={users}
          products={displayedProducts}
          plan={plan}
          currency={currency}
          subscription={subscription}
          handleUsers={handleUsers}
          handlePlan={handlePlan}
          handleCurrency={handleCurrency}
          details={detailsProps}
          euCountries={euCountries}
        />
      ) : null}

      {step === 1 && ready ? (
        plan && +users >= 100 ? (
          <div>
            <div className="summary-box">
              <InitialPriceSummary plan={plan} users={users} />
              <Button
                size="large"
                primary
                floated={'right'}
                onClick={nextstep}
                disabled={!plan}
                style={{ clear: 'both' }}
              >
                Continue
              </Button>
            </div>
          </div>
        ) : (
          <div className="text-grey" style={{ float: 'right' }}>
            Over 100 users and a plan must be selected to continue
          </div>
        )
      ) : null}

      {step === 2 ? (
        <>
          <Button size="large" floated="left" onClick={prevStep}>
            Back
          </Button>
          <Button
            primary
            size="large"
            floated="right"
            onClick={handleSubmit}
            loading={processing}
            disabled={
              processing ||
              !name?.trim() ||
              !companyName?.trim() ||
              !email?.trim() ||
              !phone?.trim() ||
              !address1?.trim() ||
              !postcode?.trim() ||
              !country?.trim() ||
              !consent
            }
          >
            {processing ? 'Processing...' : 'Confirm Details'}
          </Button>
        </>
      ) : null}
      {step === 3 ? (
        <>
          <Button size="large" floated="left" onClick={prevStep}>
            Back
          </Button>
          <Button
            primary
            size="large"
            floated="right"
            onClick={handlePayment}
            loading={processing}
            disabled={processing || !stripe || !consent}
          >
            {processing ? 'Processing...' : 'Confirm & Pay'}
          </Button>
        </>
      ) : null}
    </main>
  );
};

export default MainForm;
