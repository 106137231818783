import React from 'react';
import { Form } from 'semantic-ui-react';
import Countries from '../../static/countries.json';

interface Props {
  setCountry: any;
  country: string;
}

const CountrySelect: React.SFC<Props> = ({ country, setCountry }) => {
  const options = Countries.map((country) => {
    return {
      key: country.code,
      value: country.code,
      flag: country.code.toLowerCase(),
      text: country.name,
    };
  });

  return (
    <Form.Select
      options={options}
      id="country"
      label="Country"
      search
      selection
      placeholder="Select Country"
      value={country}
      onChange={setCountry}
      required
    />
  );
};

export default React.memo(CountrySelect);
